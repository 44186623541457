#loginpage .gradient {
  background: -moz-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: -webkit-gradient(
    linear,
    45deg,
    from(rgba(42, 27, 161, 0.7)),
    to(rgba(29, 210, 177, 0.7))
  );
  background: -o-linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(42, 27, 161, 0.7),
    rgba(29, 210, 177, 0.7) 100%
  );
}

#loginpage .view {
  background-image: url('https://mdbootstrap.com/img/Photos/Others/images/89.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 90vh;
}

#loginpage h6 {
  line-height: 1.7;
}
